import axios from 'axios';

const instance = axios.create({
   
    //  baseURL:'https://localhost:44338/'
    baseURL:'/API/'
    //    baseURL:"https://accommodation.its52.com/API/"
    
    
});

export default instance;