import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import axios from './axios-api';

import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from './hoc/Aux1/Aux1';
import * as actions from "./store/actions/index";
// import CacheBuster from './CacheBuster';
import CacheBuster from "react-cache-buster";
// import { DataTable } from "primereact/datatable";
import { version } from "../package.json";
import packageJson from "../package.json";
global.appVersion = packageJson.version;

const Auth = lazy(() => import("./containers/Auth/Auth"));
const Miqaat = lazy(() => import('./components/Miqaat/Miqaat'));
const User = lazy(() => import('./components/User/User'));
const Building = lazy(() => import('./components/Building/Building'));
const Room = lazy(() => import('./components/Room/Room - Copy'));
const Allocation = lazy(() => import('./components/Allocation/Allocation'));
const BulkAllocator = lazy(() => import('./components/BulkAllocator/BulkAllocator'));
const Launch = lazy(() => import('./components/Launch/Launch'));
const AllocationView = lazy(() => import('./components/Allocation/AllocationView'));
const AllocationDashboard = lazy(() => import('./components/AllocationDashboard/AllocationDashboard'));
const Sync = lazy(() => import('./components/Sync/Sync'));
const Tag = lazy(() => import('./components/Tag/Tag'));

const windowUrl = window.location.hash;
if(windowUrl.includes("token"))
{
    const url = windowUrl.replace("#/?id","")
    const id = url.substring(
      url.indexOf("=") + 1, 
      url.lastIndexOf("&")
  );
    let sp = url.replace(id,"");
    sp = sp.replace("=&token=","");

    localStorage.setItem('tempuser', id);
    localStorage.setItem('temptoken', sp);
    localStorage.setItem('templink', '/Ticket');
}

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Suspense
        fallback={
          <div className="loader h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" exact component={Auth} />
          {/* <Route path="/sso" component={SSO} /> */}
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Suspense
          fallback={
            <div className="loader h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Launch} />
            <Route exact path="/miqaat" component={Miqaat} />
            <Route path="/user" component={User} />
            <Route path="/building" component={Building} />
            <Route path="/room" component={Room} />
            <Route path="/allocation" component={Allocation} />
            <Route path="/allocationview" component={AllocationView} />
            <Route path="/bulkallocation" component={BulkAllocator} />
            <Route path="/dashboard" component={AllocationDashboard} />
            <Route path="/sync" component={Sync} />
            <Route path="/tag" component={Tag} />
            <Route path="/logout" component={Logout} />
            <Route path="**" render={
                () => (
                    <article>
                        <h1 className="text-danger">No Route Configured!</h1>
                        <h4 className="text-danger">Please check your Route Configuration</h4>
                    </article>
                )
            } />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Aux1>
        <CacheBuster
          currentVersion={version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={
            <div className="loader h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </div>
          } //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <Layout>{routes}</Layout>
            
        </CacheBuster>
      </Aux1>
      // <Aux1>
      //   <CacheBuster>
      //   {() => {

      //         fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      //         .then((response) => response.json())
      //         .then((meta) => {
      //           const latestVersion = meta.version;
      //           const currentVersion = global.appVersion;
      //           global.metaVersion = latestVersion;
               
      //           if(latestVersion !== currentVersion)
      //           {
      //             const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

      //             if (shouldForceRefresh) {
      //               console.log(`We have a new version - ${latestVersion}. Should force refresh`);
      //               console.log('Clearing cache and hard reloading...')
                    
      //             if (window.caches !== undefined) {
                
      //               caches.keys().then(async function(names) {
      //                 await Promise.all(names.map(name => caches.delete(name)));
      //                 });
      //             }

      //             // delete browser cache and hard reload
      //             localStorage.removeItem('token');
      //             localStorage.removeItem('expirationDate');
      //             localStorage.removeItem('userId');
      //             localStorage.removeItem('role');
      //             localStorage.removeItem('role2');
      //             localStorage.removeItem('username');
      //             localStorage.removeItem('khidmatguzar');
      //             localStorage.removeItem('link');
      //             localStorage.removeItem('photo');
      //             localStorage.removeItem('temptoken');
      //             localStorage.removeItem('tempuser');
      //             window.location.reload(true);
                    
      //             } else {
      //               console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    
      //             }
      //         }
      //         });
          
      //     return (
           
      //         <Layout>{routes}</Layout>
            
      //     );
      //   }}
      // </CacheBuster>
        
      // </Aux1>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
